@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
@import 'bootstrap/dist/css/bootstrap.min.css';

html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    background-color: linear-gradient(180deg, #121326 31.2%, #202140 91.77%);
}

body {
    background-color: #121326;
}

h1 {
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
}

h2 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 67.778px;
    line-height: 88px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    color: #eee;
}

h3 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 41.89px;
    line-height: 54px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    color: #eee;
}

h4 {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 25.888px;
    line-height: 34px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    color: #eee;
}

.bg-dark-secondary { 
    background-color: #494d52;
}

h4-l {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 25.888px;
    line-height: 34px;
    /* identical to box height */
    display: flex;
    align-items: left;
    text-align: left;
    color: #eee;
}

h5 {
    /* H5 */
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height */
    display: flex;
    align-items: center;
    text-align: center;
    color: #eee;
}

p {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height */
    display: flex;
    align-items: left;
    text-align: left;
    color: #eee;
}

.body-text {
    /* BODU TEXT */
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */
    display: flex;
    align-items: center;
    color: #eee;
}

.subheading-text {
    /* SUBHEADING */
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    display: flex;
    align-items: center;
    color: #eee;
}

.label-text {
    /* LABEL TEXT */
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 9.88875px;
    line-height: 13px;
    /* identical to box height */
    display: flex;
    align-items: center;
    color: #eee;
}

.small-text {
    /* SMALL TEXT */
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 6.11248px;
    line-height: 8px;
    /* identical to box height */
    display: flex;
    align-items: center;
    color: #eee;
}

.leaderboard-header-text {
    color: #ffcd60;
}

.home {
    background: linear-gradient(180deg, #121326 31.2%, #4186ca 78.69%);
}

.home-section {
    min-height: 65vh;
}

.home-section h2 {
    font-size: 40px;
    line-height: 100%;
    padding-top: .5em;
}

.logo-img {
    max-width: 400px;
    max-height: 80px;
    width: auto;
    height: auto;
}

.monsoon-main-img {
    max-width: 1190px;
    max-height: 1000px;
    width: 100%;
    height: 100%;
    align-content: center;
}

.monsoon-vert-img {
    max-width: 400px;
    max-height: 600px;
    width: 100%;
    height: 100%;
    align-content: center;
}

.monsoon-horiz-img {
    max-width: 650px;
    max-height: 600px;
    width: 100%;
    height: 100%;
    align-content: center;
}

.desc-img {
    max-width: 100%;
    max-height: 600px;
    width: auto;
    height: auto;
    align-content: center;
}

.animated-svg {
    min-width: 250px;
    min-height: 200px;
    height: auto;
    width: auto;
    max-width: 600px;
    max-height: 300px;
    align-content: center;
}

::-webkit-scrollbar-track {
    border: 1px solid black;
    background-color: #000;
}

::-webkit-scrollbar {
    width: 5px;
    background-color: #3e84e0;
}

::-webkit-scrollbar-thumb {
    background-color: #359ce0;
}

.arrows {
    width: 60px;
    height: 72px;
    position: relative;
    left: 50%;
    margin-left: -30px;
    bottom: 20px;
}

.arrows path {
    stroke: #ffffff;
    fill: transparent;
    stroke-width: 2px;
    animation: arrow 2s infinite;
    -webkit-animation: arrow 2s infinite;
}

.group-link {
    color: white;
    cursor: pointer;
}

.group-link:hover{
    color: lightgray;
    text-decoration: none;
}

@keyframes arrow {
    0% {
        opacity: 0;
    }

    40% {
        opacity: 1;
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@-webkit-keyframes arrow

/*Safari and Chrome*/
    {
    0% {
        opacity: 0;
    }

    40% {
        opacity: 1;
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

.arrows path.a1 {
    animation-delay: -1s;
    -webkit-animation-delay: -1s;
    /* Safari 和 Chrome */
}

.arrows path.a2 {
    animation-delay: -0.5s;
    -webkit-animation-delay: -0.5s;
    /* Safari 和 Chrome */
}

.arrows path.a3 {
    animation-delay: 0s;
    -webkit-animation-delay: 0s;
    /* Safari 和 Chrome */
}

.login-button {
    background: linear-gradient(355deg, #4240a2, #ac3f9b, #ed5382, #ff8366, #ffbe58, #f9f871);
    background-size: 1200% 1200%;
    border-color: transparent;
    -webkit-animation: AnimationName 5s ease infinite;
    -moz-animation: AnimationName 5s ease infinite;
    animation: AnimationName 5s ease infinite;
}

@-webkit-keyframes AnimationName {
    0% {
        background-position: 0% 68%;
    }

    50% {
        background-position: 100% 33%;
    }

    100% {
        background-position: 0% 68%;
    }
}

@-moz-keyframes AnimationName {
    0% {
        background-position: 0% 68%;
    }

    50% {
        background-position: 100% 33%;
    }

    100% {
        background-position: 0% 68%;
    }
}

@keyframes AnimationName {
    0% {
        background-position: 0% 68%;
    }

    50% {
        background-position: 100% 33%;
    }

    100% {
        background-position: 0% 68%;
    }
}

.profile {
    background: linear-gradient(180deg, #121326 0%, #202140 100%);
}

.blur-navbar {
    background-color: rgba(0, 0, 0, 0.13);
    backdrop-filter: blur(5px);
    border-bottom: solid;
    border-bottom-width: 0.5px;
    border-bottom-color: #ffffffa3;
}

#top-navbar {
    -webkit-transition: all 0.8s ease;
    -moz-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
    transition: all 0.8s ease;
}

button.menu-button {
    outline: none;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    position: absolute;
    top: 18px;
    left: 15px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: transparent;
    z-index: 999;
}

ul, li {
    margin: 0;
    padding: 0;
    z-index: 999;
}

ul {
    padding: 25px;
    position: absolute;
    top: 100px;
    width: 230px;
    z-index: 999;
}

li {
    list-style: none;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 999;
}

.menu-icon {
    vertical-align: middle;
    z-index: 999;
}

.menu-text {
    color: black;
    text-decoration: underline;
    white-space: nowrap;
    vertical-align: middle;
    z-index: 999;
    font-size: 1.5em;
}

.menu-background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background: white;
    z-index: 999;
}

.menu-container {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    max-height: 100vh;
    max-width: 100vw;
    width: 400px;
    box-sizing: border-box;
}

.menu-container ul {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    max-height: 100vh;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    padding-top: 90px;
    padding-left: 60px;
}

.title-text {
    position: relative;
    left: 50%;
    transform: translateX(-10%);
    top: -85px;
}

.card-small {
    background: linear-gradient(180deg, #2a2c4a 0%, #303163 100%);
    border-radius: 15px;
    min-width: 200px;
    min-height: 200px;
    max-width: fit-content;
    max-height: fit-content;
}

.card-medium {
    background: linear-gradient(180deg, #2a2c4a 0%, #303163 100%);
    border-radius: 15px;
    min-width: 300px;
    min-height: 300px;
    max-width: fit-content;
    max-height: fit-content;
}

.card-large {
    background: linear-gradient(180deg, #2a2c4a 0%, #303163 100%);
    border-radius: 15px;
    margin-left: 5%;
    margin-right: 5%;
}

.card-leaderboard {
    background: linear-gradient(180deg, #2a2c4a 0%, #303163 100%);
    border-radius: 15px;
}

.resources-container {
    opacity: 0;
    transition: opacity 0.5s;
}

.resources-row {
    display: flex;
    align-content: left;
    padding-top: 2%;
}

.resource-card {
    background-color: #1a1c35;
    margin-bottom: 1rem;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    max-width: 1100px; /* so card doesn't stretch too much on desktop */
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.resource-card .card-body {
    padding: 1rem;
}

.resource-card .card-title a {
    color: #f8cf76;
}

.resource-card .card-text {
    color: #ccc;
}

.button-tag {
    border-color: transparent;
    margin: 5px;
    font-size: 12px;
    padding: 5px 10px;
}

.button-tag.inactive {
    background-color: #CCC;
    color: black;
}

.tags-container {
    display: inline-flex;
    gap: 8px;
    margin-left: 10px;
}

.tag {
    padding: 2px 8px;
    border-radius: 4px;
    color: white;
    font-size: 0.75rem;
    white-space: nowrap;
}

.form-control {
    background-color: #535699;
    border: none;
    color: white;
}

.terms-list {
    margin-left: 1.5em;
    margin-top: .25em;
    padding: 0;
    padding-right: 1em;
}

.terms-list li {
    display: block;
    cursor: inherit;
}

button.submit-button {
    outline: none;
    border: none;
    background: linear-gradient(270deg, #18c190, #00c9ba, #00baff);
    background-size: 600% 600%;
    margin-bottom: 10px;
    -webkit-animation: submit-button-animation 6s ease infinite;
    -moz-animation: submit-button-animation 6s ease infinite;
    animation: submit-button-animation 6s ease infinite;
}

button.submit-button:active {
    opacity: 1.2;
}

button.danger-button {
    outline: none;
    border: none;
    background: linear-gradient(270deg, #862332, #9a2839, #b5293e);
    background-size: 600% 600%;
    margin-bottom: 10px;
    -webkit-animation: submit-button-animation 6s ease infinite;
    -moz-animation: submit-button-animation 6s ease infinite;
    animation: submit-button-animation 6s ease infinite;
}

button.danger-button:active {
    opacity: 1.2;
}

button.secondary-button {
    outline: none;
    border: none;
    background: linear-gradient(270deg, #646665, #6c6c6c, #757575);
    background-size: 600% 600%;
    margin-bottom: 10px;
    -webkit-animation: submit-button-animation 6s ease infinite;
    -moz-animation: submit-button-animation 6s ease infinite;
    animation: submit-button-animation 6s ease infinite;
}

button.secondary-button:active {
    opacity: 1.2;
}

@-webkit-keyframes submit-button-animation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@-moz-keyframes submit-button-animation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@keyframes submit-button-animation {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.estimate-background {
    position: absolute;
    width: 100%;
    height: 100%;
}

.axis {
    color: white;
}

.axis-label-text {
    fill: #000;
    fill-opacity: 0.9;
    font-size: 12px;
    text-anchor: middle;
}

.test {
    fill: none;
    stroke: red;
}

.viz-line {
    fill: none;
    stroke: #ffcd60;
    stroke-width: 2.25;
}

.mean-line {
    fill: none;
    stroke: #f0534d;
    stroke-width: 6;
}

.med-line {
    fill: none;
    stroke: #24fc85;
    stroke-width: 6;
}

.estimate-line {
    fill: none;
    stroke: #ffcd60;
    stroke-width: 6;
}

.whitespace {
    margin-right: 0.3em;
}

.axisWhite line {
    stroke: white;
}

.axisWhite path {
    stroke: white;
}

.axisWhite text {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    fill: white;
}

.axisLabel {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    fill: white;
}

.axisLabel-mobile {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    fill: white;
}

.mean-label {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    fill: #f0534d;
}

.med-label {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    fill: #24fc85;
}

.modal-content {
    background-color: #2e2f5a;
}

.Toastify__toast--error {
    background: #f0534d;
}

.Toastify__toast--success {
    background: #3cbc74;
}

.Toastify__toast--info {
    background: #3aabff;
}

.bg-collapse {
    background-color: #464c52;
}
